import { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';

import getValidRoute from 'utils/getValidRoute';
import useDebounceCallback from 'hooks/useDebounceCallback';

import Select from './components/Select';
import {
  adsOptions,
  categoryOptions,
  FILTERS,
  getFilters,
  LABELS,
  positionOptions,
} from '../utils';

import { useLinkAndFilterStyles } from './styles';

const LinkAndFilter = ({ data, resetData, adFilters, handleDataChange }) => {
  const { root } = useLinkAndFilterStyles();

  const { content, filters, main } = data;

  const urlStructure = useDebounceCallback({
    callback: getValidRoute,
    value: main.path,
    defaultValue: {},
    args: {
      slug:
        console.log(main.path.split('?')[0].split('/'), '>>>>>') ||
        main.path.split('?')[0].split('/'),
      categories: adFilters.categories,
      locationsData: adFilters.location,
    },
  });

  const { isValidUrl, selectedCity, category_id, label_ids } = urlStructure || {};

  useEffect(() => {
    if (isValidUrl) {
      resetData('main');
    }
  }, [isValidUrl, resetData]);

  const selectedCategory = adFilters.categories.find(({ id }) => id === category_id);
  const selectedLabel = selectedCategory?.sections.find(({ id }) => id === label_ids?.[0]);

  return (
    <div className={root}>
      <div className="first-step-title">
        <span>Step 1:</span> Link&Filter
      </div>
      <div>
        <div>
          <div className="url-title">
            <span>*</span>Landing page URL
          </div>
          <div className="input-field flex">
            <div className="host">https://www.sexjobs.nl/</div>
            <TextField
              fullWidth
              value={main.path}
              variant="outlined"
              onChange={e =>
                handleDataChange({ sectionKey: 'main', contentKey: 'path', data: e.target.value })
              }
            />
          </div>
        </div>

        <div className="level-group">
          <div className="flex">
            <Select
              label="Ads"
              value={content.ads}
              options={adsOptions}
              handleChange={e =>
                handleDataChange({ sectionKey: 'content', contentKey: 'ads', data: e.target.value })
              }
            />

            {content.ads === 'Show Ads' ? (
              <Select
                className="m-20"
                value={content.position}
                options={positionOptions}
                handleChange={e =>
                  handleDataChange({
                    sectionKey: 'content',
                    contentKey: 'position',
                    data: e.target.value,
                  })
                }
              />
            ) : (
              <div className="full-width m-20" />
            )}
            <div className="full-width" />
          </div>
        </div>
        <div>
          <div className="flex">
            <Select
              disabled
              label="Level 1"
              options={categoryOptions}
              value={filters.levelOne.label}
            />
            <Select
              disabled={isValidUrl}
              type={FILTERS}
              className="m-20"
              label="Level 2"
              options={getFilters(filters, 'levelThree', adFilters)}
              value={isValidUrl && selectedLabel ? 'label' : filters.levelTwo.label}
              handleChange={e =>
                handleDataChange({
                  sectionKey: FILTERS,
                  contentKey: 'levelTwo',
                  data: { label: e.target.value },
                  needToReset: true,
                })
              }
            />
            <Select
              disabled={isValidUrl}
              type={FILTERS}
              label="Level 3"
              options={getFilters(filters, 'levelTwo', adFilters)}
              value={isValidUrl && selectedCity ? 'location' : filters.levelThree.label}
              handleChange={e =>
                handleDataChange({
                  sectionKey: FILTERS,
                  contentKey: 'levelThree',
                  data: { label: e.target.value },
                  needToReset: true,
                })
              }
            />
          </div>
          <div className="flex">
            <Select
              disabled={isValidUrl}
              options={adFilters.categories}
              handleChange={e =>
                handleDataChange({
                  sectionKey: FILTERS,
                  contentKey: 'levelOne',
                  data: { value: e.target.value },
                })
              }
              value={isValidUrl && category_id ? selectedCategory.name : filters.levelOne.value}
            />
            <Select
              multiple={filters.levelTwo.label !== LABELS}
              className="m-20"
              disabled={!adFilters[filters.levelTwo.label]}
              handleChange={e =>
                handleDataChange({
                  sectionKey: FILTERS,
                  contentKey: 'levelTwo',
                  data: { value: e.target.value },
                })
              }
              options={adFilters[filters.levelTwo.label]}
              value={isValidUrl && selectedLabel ? [selectedLabel.name] : filters.levelTwo.value}
            />
            <Select
              multiple={filters.levelThree.label !== LABELS}
              disabled={!adFilters[filters.levelThree.label]}
              handleChange={e =>
                handleDataChange({
                  sectionKey: FILTERS,
                  contentKey: 'levelThree',
                  data: { value: e.target.value },
                })
              }
              options={adFilters[filters.levelThree.label]}
              value={isValidUrl && selectedCity ? [selectedCity] : filters.levelThree.value}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkAndFilter;
