export const LOCATION_PREFIX = '[LOCATION]';

const getMetaPath = ({ selectedCategory, selectedLocation, selectedSubCategory }) => {
  if (!selectedLocation) return '';

  let metaPath;

  if (selectedCategory) {
    metaPath = `/${selectedCategory.value_nl}/${LOCATION_PREFIX}`;

    if (selectedSubCategory) {
      metaPath = `/${selectedCategory.value_nl}/${selectedSubCategory.value_nl}/[LOCATION]`;
    }
  } else {
    metaPath = '/[LOCATION]';
  }

  return { metaPath, location: selectedLocation };
};

export default getMetaPath;
