import { BsPlusCircle } from 'react-icons/bs';
import { ContentShadowAndWhite } from 'styles/global-styles';

import CreateLandingPageDialog from './CreateLandingPageDialog';

import { useStyles } from './styles';
import { useState } from 'react';
import { accountDetailsService, categorySettingsService } from 'services';
import useMount from 'hooks/useMount';
import { CATEGORIES, getSelectOptions, PRICING, pricingOptions } from './utils';

function SEOStatic() {
  const { root, header } = useStyles();

  const [adFilters, setAdFilters] = useState({});
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  useMount(() => {
    (async () => {
      try {
        const [filtersResponse, categoriesResponse, locationsResponse] = await Promise.all([
          accountDetailsService.getAdFilters(),
          categorySettingsService.getCategories(),
          accountDetailsService.getProvinces(),
        ]);

        const {
          data: { filters },
        } = filtersResponse;

        const {
          data: { categories },
        } = categoriesResponse;

        const { data } = locationsResponse;

        const result = Object.entries(filters).reduce((acc, [key, value]) => {
          if (key === CATEGORIES) {
            return acc;
          }

          if (key === PRICING) {
            acc[key] = pricingOptions;
          } else if (value.section) {
            Object.assign(
              acc,
              Object.entries(value.section).reduce((acc, [sectionKey, sections]) => {
                acc[sectionKey] = getSelectOptions(sections);
                return acc;
              }, {}),
            );
          } else {
            acc[key] = getSelectOptions(value.tabs || value);
          }

          return acc;
        }, {});

        const categoriesOptions = getSelectOptions(Object.values(categories));

        result.categories = categoriesOptions;
        result.location = getSelectOptions(data);
        result.label = getSelectOptions(categoriesOptions.flatMap(({ sections }) => sections));

        setAdFilters(result);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    })();
  });

  return (
    <div className={root}>
      <ContentShadowAndWhite>
        <div className={header}>
          <div className="title">Seo MetaData</div>
          <BsPlusCircle onClick={() => setIsOpenDialog(true)} className="add-icon" size={30} />
        </div>
      </ContentShadowAndWhite>
      <ContentShadowAndWhite>{/* Table */}</ContentShadowAndWhite>
      {isOpenDialog && (
        <CreateLandingPageDialog
          adFilters={adFilters}
          handleApprove={() => {}}
          setIsOpenDialog={setIsOpenDialog}
        />
      )}
    </div>
  );
}

export default SEOStatic;
