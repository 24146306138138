import getMetaPath from './getMetaPath';

const provinceCodes = ['dr', 'fl', 'fr', 'gd', 'gr', 'lb', 'nb', 'nh', 'ov', 'ut', 'zh', 'zl'];

const getValidRoute = ({ slug = [], categories, locationsData }) => {
  // This case handle cases for duplicate names in different provinces
  let provinceCode;

  const locations = locationsData.reduce((acc, elem) => {
    acc[elem.name.toLowerCase()] = elem.cities;

    return acc;
  }, {});

  const selectedCategory = categories.find(({ slug: value_nl }) => slug?.includes(value_nl));

  const selectedLabel = selectedCategory?.sections?.find(({ slug: value_nl }) =>
    slug.includes(value_nl),
  );

  // This is handling case for cities which have province code with them
  const slugWithoutProvinceCode = slug.map(item => {
    const [lastPartOfCityName] = item.split('-').slice(-1);

    const hasProvinceCode = provinceCodes.includes(lastPartOfCityName);

    if (hasProvinceCode) {
      provinceCode = lastPartOfCityName;
    }

    return hasProvinceCode ? item.slice(0, -3) : item;
  });

  const selectedCities = Object.values(locations)
    .flat()
    .filter(({ name }) => slugWithoutProvinceCode?.includes(name.toLowerCase().replace(/ /g, '-')));

  const selectedCity =
    selectedCities.length === 1
      ? selectedCities[0]
      : selectedCities.find(({ province_code }) => province_code === provinceCode);

  const provincePath = slug?.find(el => el.includes('provincie-'));

  const [, province] = provincePath?.split('provincie-') || [];

  const isSelectedProvince = locations[province];

  const type = isSelectedProvince ? 'province' : selectedCity ? 'settlement' : '';

  const selectedLocation =
    (isSelectedProvince && province) || selectedCity?.name.toLowerCase().replace(/ /g, '-');

  const metaData = getMetaPath({
    selectedCategory,
    selectedLocation,
    selectedLabel,
  });

  if (slug?.length === 1) {
    const [path] = slug;
    const isSearch = path === 'search';
    const isAvailableNow = path === 'nu-beschikbaar';
    const isValidUrl =
      isSearch || isAvailableNow || selectedCategory || isSelectedProvince || selectedCity;

    return {
      isValidUrl: !!isValidUrl,
      type,
      isSearch,
      isAvailableNow,
      selectedCity: selectedCity?.name,
      category_id: selectedCategory?.id,
      selectedProvince: province,
      provinceCode,
      metaData,
    };
  }
  if (slug?.length === 2) {
    const isValidUrl = selectedCategory && (selectedLabel || selectedCity || isSelectedProvince);

    return {
      type,
      provinceCode,
      isValidUrl: !!isValidUrl,
      selectedCity: selectedCity?.name,
      selectedProvince: province,
      category_id: selectedCategory?.id,
      label_ids: selectedLabel?.id ? [selectedLabel?.id] : [],
      metaData,
    };
  }
  if (slug?.length === 3) {
    const isValidUrl = selectedCategory && selectedLabel && (selectedCity || isSelectedProvince);

    return {
      type,
      provinceCode,
      isValidUrl: !!isValidUrl,
      selectedCity: selectedCity?.name,
      selectedProvince: province,
      category_id: selectedCategory?.id,
      label_ids: selectedLabel?.id ? [selectedLabel?.id] : [],
      metaData,
    };
  }
};

export default getValidRoute;
