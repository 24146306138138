import { useState, useCallback, memo } from 'react';
import clsx from 'clsx';
import MUIDialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import isObject from 'utils/isObject';

import Content from './Content';
import SEOData from './SEOData';
import LinkAndFilter from './LinkAndFilter';
import { useDialogStyles } from './styles';
import { ReactComponent as FaClose } from 'images/faClose.svg';

const steps = {
  1: LinkAndFilter,
  2: SEOData,
  3: Content,
};

const defaultState = {
  filters: {
    levelOne: { label: 'Category' },
    levelTwo: {},
    levelThree: {},
  },
  main: { path: '' },
  content: { ads: '', position: '' },
};

const CreateLandingPageDialog = ({ adFilters, handleApprove, setIsOpenDialog }) => {
  const [data, setData] = useState(defaultState);
  const [currentStep, setCurrentStep] = useState(1);

  const { root, dialogTitle } = useDialogStyles();

  const Component = steps[currentStep];

  const handleDataChange = useCallback(({ sectionKey, contentKey, data, needToReset }) => {
    setData(prev => ({
      ...prev,
      [sectionKey]: {
        ...prev[sectionKey],
        [contentKey]: isObject(prev[sectionKey][contentKey])
          ? {
              ...prev[sectionKey][contentKey],
              ...data,
              ...(needToReset && prev[sectionKey][contentKey].value && { value: undefined }),
            }
          : data,
      },
    }));
  }, []);

  const resetData = useCallback(
    excluded =>
      setData(prev => ({
        ...defaultState,
        ...(excluded && { [excluded]: prev[excluded] }),
      })),
    [],
  );

  return (
    <MUIDialog
      open
      maxWidth="lg"
      className={root}
      disableBackdropClick
      onClose={() => setIsOpenDialog(null)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={dialogTitle} id="alert-dialog-title">
        <div>
          <div className="title">Create Landing page</div>
          <div className="close">
            <FaClose width="20px" height="20px" onClick={() => setIsOpenDialog(null)} />
          </div>
        </div>
        <hr />
      </DialogTitle>
      <DialogContent style={{ minWidth: '860px' }}>
        <Component
          data={data}
          resetData={resetData}
          adFilters={adFilters}
          handleDataChange={handleDataChange}
        />
      </DialogContent>
      <DialogActions className="dialog-actions">
        <div>
          {currentStep > 1 && (
            <Button
              className={clsx({ purple: currentStep === 2, blueGray: currentStep === 3 })}
              variant="contained"
              onClick={() => {}}
              color="primary"
            >
              {currentStep === 2 ? 'Autocomplete' : 'Preview'}
            </Button>
          )}
        </div>
        <div>
          <Button
            variant="contained"
            onClick={() =>
              currentStep === 1 ? setIsOpenDialog(null) : setCurrentStep(prev => prev - 1)
            }
            color="secondary"
          >
            {currentStep === 1 ? 'Cancel' : 'Prev step'}
          </Button>
          <Button
            variant="contained"
            onClick={() => (currentStep === 3 ? handleApprove() : setCurrentStep(prev => prev + 1))}
            color="primary"
          >
            {currentStep < 3 ? 'Next step' : 'Create'}
          </Button>
        </div>
      </DialogActions>
    </MUIDialog>
  );
};

export default memo(CreateLandingPageDialog);
